export const applicationKeys = [
  'SMART_MODELS',
  'INSPECTIONS',
  'CONTROL_PANEL',
  'ANALYTICS',
  'DEMO',
  'SMART_SAMPLINGS',
  'VARIABLE_MAPS',
  'YIELD_MAPS',
  'HARVEST_PLAN',
] as const;
export type ApplicationKey = (typeof applicationKeys)[number];

// RESPONSE
export type ApplicationsGetResponse = ApplicationKey[];
export interface ApplicationsGetFacadeResponse {
  apps: ApplicationKey[];
}
